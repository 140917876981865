import { gql } from '@apollo/client';

export const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestToken($email: String!, $password: String!, $clientType: String!) {
    tokenAuth(email: $email, password: $password, clientType: $clientType) {
      token,
      refreshToken,
      user {
        uid,
        emailVerified
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token,
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export const DISCONTINUE_CARE_MUTATION = gql`
  mutation DiscontinueCare($careteamUid: String!) {
    discontinueCare(careteamUid: $careteamUid) {
      success
    }
  }
`;

export const UPDATE_LOCAL_TIMEZONE = gql`
  mutation UpdateLocalTimezone($localTimezone: String!) {
      updateLocalTimezone(localTimezone: $localTimezone) {
          user {
              localTimezone
          }
      }
  }
`;
