import { red } from '@material-ui/core/colors';
// this is done so that material ui plays nice with StrictMode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F2E81',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Proxima Nova',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#FEFEFE',
        boxShadow: `-4px -4px 8px rgba(0, 0, 0, 0.06),
                    4px -4px 8px rgba(0, 0, 0, 0.06),
                    -4px 4px 8px rgba(0, 0, 0, 0.06),
                    4px 4px 8px rgba(0, 0, 0, 0.06)`,
        overflow: 'visible',
        maxWidth: null,
      },
      arrow: {
        color: '#FEFEFE',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#089a7e',
        },
        '&$active': {
          color: '#089a7e',
        },
      },
      active: {},
      completed: {},
    },
  },
});

export default theme;
