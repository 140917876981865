import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { CssBaseline, Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { EnvironmentSwitcher, LoadingSpinner } from 'react-limbix-ui';

import theme from './theme';
import './index.css';

import { initializeAnalytics } from '@/utils/analytics';
import { client } from '@/apollo';
import store from '@/store';
import history from '@/utils/history';

const App = React.lazy(() => import('./App'));
const Login = React.lazy(() => import('@/pages/Common/Login'));
const GetSpark = React.lazy(() => import('@/pages/Common/GetSpark'));
const ResetPassword = React.lazy(() => import('@/pages/Common/ResetPassword'));

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c4e9832bffc44146b74f391bf0f53d3d@o58970.ingest.sentry.io/5954092',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `provider@${process.env.APP_VERSION}`,
  });
}

initializeAnalytics();

const SuspenseLoading = () => (
  <Box height="100vh" width="100vw" display="grid" alignContent="space-around" justifyContent="space-around">
    <LoadingSpinner size="150px" />
  </Box>
);

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <ApolloProvider client={client}>
            <Router history={history}>
              <Suspense fallback={<SuspenseLoading />}>
                <Switch>
                  <Route path="/login" exact component={Login} />
                  <Route path="/reset-password" exact component={ResetPassword} />
                  <Route path="/get-spark" exact component={GetSpark} />
                  <Route component={App} />
                </Switch>
              </Suspense>
            </Router>
            <EnvironmentSwitcher side="left" bottom="35px" />
          </ApolloProvider>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
