export const LANDING_PAGE_ROUTE = '/login';
export const PDT_UID = '8dpNPSmSnyN3vxeBi4RtM9';
export const GENDER_MAP = {
  M: 'Male',
  F: 'Female',
  X: 'Non-Binary',
  O: 'Other',
};
export const ADHERENCE_SCORE_DATA = [{
  text: 'N/A',
  bgcolor: '#F3F3F3',
  color: '#9B9CA0',
},
{
  text: 'Low',
  bgcolor: '#FFF3EE',
  color: '#DF5F30',
},
{
  text: 'Medium',
  bgcolor: '#FFF8E5',
  color: '#C69101',
},
{
  text: 'High',
  bgcolor: '#EFFBF8',
  color: '#089A7E',
}];

export const US_STATES = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
  'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
  'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
  'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

export const INCOMPLETE_PHQ_Y_VALUE = -4;
export const HASNT_STARTED_PROGRAM = 'hasn\'t started program';
export const INCOMPLETE_ASSESSMENT_DATA = 'incomplete assessment data';

export const PHQ_STRING_TO_TABLE_VALUE = new Map([
  [HASNT_STARTED_PROGRAM, -1],
  ['-', -1],
  [INCOMPLETE_ASSESSMENT_DATA, -2],
]);
