import { IdleTimerPayloadType } from './DataTypes';

import { MutationResultType } from './ApolloTypes';

/* Global Action/Reducer Types */
export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

/* Provider User Action/Reducer Types */
export type ProviderUserType = {
  isLoggedIn: boolean;
  hasAccess: boolean;
  isAdmin: boolean;
  isLimbix: boolean;
};

export enum ProviderUserActionTypes {
  updateProviderUser = 'UPDATE_PROVIDER_USER',
}

export type ProviderUserAction = {
  type: ProviderUserActionTypes;
  payload: ProviderUserType;
}

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  message?: string;
};

export enum NavigationActionTypes {
  blockNavigation = 'BLOCK_NAVIGATION',
  unblockNavigation = 'UNBLOCK_NAVIGATION',
}

export type NavigationAction = {
  type: NavigationActionTypes;
  payload?: {
    message: string;
  };
}

/* Units Action/Reducer Types */
export type TimeUnit = 'Minutes' | 'Hours';

export type UnitsType = {
  [key: string]: string;
  time: TimeUnit;
};

export enum UnitsActionTypes {
  UpdateUnits = 'UPDATE_UNITS',
}

export type UnitsAction = {
  type: UnitsActionTypes;
  payload: UnitsType;
}

/* Modal Action/Reducer Types */
export enum ModalActionTypes {
  ShowModal = 'SHOW_MODAL',
  HideModal = 'HIDE_MODAL',
}

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  CONFIRMATION_POPUP: {
    header: string;
    body: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    onConfirm: () => void;
  };
  CONFIRMATION_POPUP_WITH_MUTATION: {
    header: string;
    body: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    mutation: () => Promise<MutationResultType>;
  };
};

export type ModalContentType = keyof ModalPayloadType;

export type ShowModalAction<ModalContent extends ModalContentType> = { type: ModalActionTypes.ShowModal }
  & {
  payload: {
    modalType: ModalContent,
    payload: ModalPayloadType[ModalContent],
  }
};

export type ModalAction = {
  type: ModalActionTypes.HideModal;
} | ShowModalAction<ModalContentType>;
