enum EnvironmentEnum {
  DEVELOPMENT = 'development',
  DEV = 'dev',
  TESTING = 'testing',
  STAGING = 'staging',
  STABLE = 'stable',
  PRODUCTION = 'production',
  PR = 'pr',
}

type EnvironmentEnumType = `${EnvironmentEnum}`;

export const envSwitch = (envOptions: Record<EnvironmentEnum, string>, overrideEnv?: EnvironmentEnumType): string => (
  envOptions[overrideEnv ?? process.env.REACT_APP_DEPLOY_ENV as EnvironmentEnum]
);
