import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import ProviderUserReducer from './providerUserReducer';
import ModalReducer from './modalReducer';
import UnitsReducer from './unitsReducer';
import NavigationReducer from './navigationReducer';

import { GlobalActionTypes } from '@/types';

const appReducer = combineReducers({
  providerUser: ProviderUserReducer,
  modal: ModalReducer,
  units: UnitsReducer,
  navigation: NavigationReducer,
});

// eslint-disable-next-line
const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === GlobalActionTypes.ClearStore) {
    return appReducer(undefined, ({ type: undefined } as never));
  }
  return appReducer(state, (action as never));
};

export default rootReducer;
