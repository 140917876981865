import {
  UnitsType,
  UnitsAction,
  UnitsActionTypes,
} from '@/types/ReduxTypes';

export type UnitsStateType = {
  units: UnitsType;
};

const defaultUnitsTypeState: UnitsType = {
  time: 'Minutes',
};

export const defaultUnitsState: UnitsStateType = {
  units: { ...defaultUnitsTypeState } as UnitsType,
};

const unitsState: UnitsStateType = { ...defaultUnitsState };

const UnitsReducer = (
  state: UnitsStateType = unitsState,
  dispatched: UnitsAction,
): UnitsStateType => {
  switch (dispatched.type) {
  case UnitsActionTypes.UpdateUnits:
    return {
      ...state,
      units: { ...dispatched.payload },
    };
  default:
    return state;
  }
};

export default UnitsReducer;
