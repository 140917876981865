import {
  ProviderUserType,
  ProviderUserActionTypes,
  ProviderUserAction,
} from '@/types/ReduxTypes';

type ProviderUserStateType = {
  providerUser: ProviderUserType;
};

const defaultProviderUserTypeState: ProviderUserType = {
  isLoggedIn: false,
  hasAccess: false,
  isAdmin: false,
  isLimbix: false,
};

export const defaultProviderUserState: ProviderUserStateType = {
  providerUser: { ...defaultProviderUserTypeState } as ProviderUserType,
};

const providerUserState: ProviderUserStateType = { ...defaultProviderUserState };

const ProviderUserReducer = (
  state: ProviderUserStateType = providerUserState,
  dispatched: ProviderUserAction,
): ProviderUserStateType => {
  switch (dispatched.type) {
  case ProviderUserActionTypes.updateProviderUser:
    return {
      ...state,
      providerUser: { ...dispatched.payload },
    };
  default:
    return state;
  }
};

export default ProviderUserReducer;
