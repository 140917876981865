import { EventArgs, FieldsObject } from 'react-ga';
import ReactGA from 'react-ga4';

import { envSwitch } from './environmentUtils';
import history from './history';

const shouldTrackAnalytics = process.env.NODE_ENV !== 'development' && process.env.REACT_APP_DEPLOY_ENV !== 'pr';

export const setAnalyticsFields = (fieldsObject: FieldsObject): void => {
  if (shouldTrackAnalytics) {
    ReactGA.set(fieldsObject);
  }
};

export const initializeAnalytics = (): void => {
  if (shouldTrackAnalytics) {
    const measurementId = envSwitch({
      development: 'G-0XWY4D9CKF',
      dev: 'G-0XWY4D9CKF',
      testing: 'G-0XWY4D9CKF',
      staging: 'G-0XWY4D9CKF',
      stable: 'G-8NPWPQ4H0J',
      production: 'G-Z2PTC6K4TF',
      pr: null,
    });
    ReactGA.initialize(
      measurementId,
      {
        legacyDimensionMetric: false,
        gtagOptions: {
          send_page_view: true,
        },
        gaOptions: {
          environment: process.env.REACT_APP_DEPLOY_ENV,
        },
      },
    );
    const userId = localStorage.getItem('uid');
    setAnalyticsFields({ userId });

    history.listen((location) => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + window.location.search });
    });
  }
};

export const logEvent = (args: EventArgs): void => {
  if (shouldTrackAnalytics) {
    ReactGA.event(args);
  }
};

export const withAnalytics = <T, >(wrappedFunction: () => T, args: EventArgs): T => {
  logEvent(args);
  return wrappedFunction();
};
